import * as React from "react"
import styled from "styled-components";
import { Link } from 'gatsby';
import Pill from 'src/components/pill'
import Badge from 'src/components/badge'
import ResponsiveImage from "./responsive-image";


// ...

const LearnLink = styled(props => <Link {...props} />)`
  display: block;
  box-sizing: border-box;
  margin-top: 8px;
  @media (min-width: 600px){
    position: absolute;
    bottom: 0;
  }
  width: 100%;
  text-align: right;
  color: #ff008c;
  font-family: Merriweather, serif;
  text-decoration-line: none;
  // padding-right: 2px;
  cursor: pointer;
  font-size: 1.2em;
`
const NoDecLink = styled(props => <Link {...props} />)`
  :link, :visited, :active{
    text-decoration: none;
  }
`
const Content = styled.div`
  font-size: 1.2em;
  line-height: 1.5;
  font-family: Merriweather, serif;
  @media (min-width: 768px){
    text-align: justify;
    text-justify: newspaper;
  }
`
const Image = styled(ResponsiveImage)`
    height: 256px;
    width: 256px;
    border-radius: 8px;
    @media (max-width: 600px){
      height: auto;
      width: 100%;
      margin: 0 0 8px 0;
    }
`
const Main = styled.div`
  border-bottom: 1px solid #c8c8c8;
  padding: 8px 0;
  margin: 8px 0;
  @media (min-width: 600px) {
    margin: 48px 0;
    border-bottom: none;
    min-height: 256px;
    display: flex;
    flex-direction: row;
    //img {
    //  filter: contrast(200%) brightness(60%) saturate(0%);
    //  //filter: contrast(200%) brightness(60%) sepia(100%) saturate(300%) hue-rotate(-75deg);
    //  transition: filter 0.25s;
    //}
    //
    //:hover img {
    //  filter: none;
    //}
    
    ${Image} {
      opacity: 0.5;
      transition: opacity 0.25s;
    }

    :hover ${Image} {
      opacity: 1;
    }

    ${LearnLink} {
      opacity: 0;
      transition: opacity 0.25s;
    }

    :hover ${LearnLink} {
      opacity: 1;
    }
    
    ${Content} {
      transition: color 0.25s;
    }
    :hover ${Content} {
      color: #ffffff;
    }
    
    box-sizing: border-box;
    padding: 0 8px 0 0;
    border-radius: 8px;
    box-shadow: 0 0 0 0 #ffffff00;
    //transition: box-shadow 0.5s;
    //:hover {
    //  box-shadow: 4px 4px 0 4px #ffffff, -4px -4px 0 4px #ff008c;
    //}
    
  }
`
const ImgLink = styled(props => <Link {...props} />)`
  @media (min-width: 600px) {
    margin-right: 16px;
    width: 256px;
    height: 256px;
    flex: 0 0 256px;
    :link, :visited, :active{
      height: 256px;
    }
  }
`
const ContentCard = styled.div`
  flex: 2 2 auto;
  position: relative;
  @media (min-width: 600px){
    padding-bottom: 20px;
  }
`
const Header2 = styled.h2`
  //display: inline;
  font-family: Merriweather, serif;
  font-size: 2em;
  margin: 0 8px 0 0;
  @media (max-height: 600px){
    margin: 10px 0 0 0;
  }
  color: #ffffff;
  hyphens: auto;
  :link, :visited, :active{
    text-decoration-color: transparent;
  }

  transition: color 0.25s;

  :hover {
    color: #ff008c;
  }
`
const Date = styled.div`
  color: #888888;
  font-family: Merriweather, serif;
  margin-bottom: 12px;
  font-size: 1.2em;
`
const TagContainer = styled.div`
  margin: 12px 0;
`
const Anchor = styled.div`
  position: relative;
  top: -120px;
  visibility: hidden;
`

const PortfolioCard = (props) => {
    return (
        <Main>
            <Anchor id={props.data.frontmatter.path}/>
            <ImgLink to={"/portfolio/" + props.data.frontmatter.path}><Image src={props.data.frontmatter.thumbnail}/></ImgLink>
            <ContentCard>
                <NoDecLink to={"/portfolio/" + props.data.frontmatter.path}>
                    <Header2>
                        {props.data.frontmatter.title}
                    </Header2>
                </NoDecLink>
                {(props.data.frontmatter.badge && (
                    <Badge tag={props.data.frontmatter.badge}/>
                ))}
                <Date>{props.data.frontmatter.date}</Date>
                <Content>{props.data.frontmatter.summary}</Content>
                <TagContainer>
                    {
                        props.data.frontmatter.tags?.map((tag) =>
                            <Pill tag={tag}/>
                        )
                    }
                </TagContainer>
                <LearnLink to={"/portfolio/" + props.data.frontmatter.path}>> Learn more</LearnLink>
            </ContentCard>
        </Main>
    )
};

export default PortfolioCard
