import * as React from 'react'
import styled from "styled-components";

const Main = styled.div`
  font-family: Staatliches, sans-serif;
  background-color: #ffbb00;
  font-size: 1.1em;
  color: #181818;
  width: fit-content;
  padding: 0 8px 0 4px;
  margin: 4px 0;
  border-radius: 4px;
  ::before{
    font-size: 0.9em;
    content: "🎉";
    padding-right: 1px;
  }
`

const Badge = (props) => {
    return(
        <Main>
            {props.tag}
        </Main>
    )
}

export default Badge