import * as React from "react"
import styled from "styled-components";
import "@fontsource/staatliches"

const PillTag = styled.span`
  display: inline-block;
  padding: 0 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 20px;
  border-color: #eeeeee;
  border-width: 2px;
  border-style: solid;
  color: #eeeeee;
  font-size: 1em;
  font-family: Staatliches, sans-serif;
`

const Pill = (props) => {
    return(
        <PillTag>{props.tag}</PillTag>
    )
}

export default Pill