import * as React from "react"
import styled from "styled-components";
import {useStaticQuery, graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const Main = styled.div`
`

const ResponsiveImage = (props) => {
    const { allImageSharp } = useStaticQuery(graphql`
        query{
          allImageSharp {
            nodes {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {color: "#ff008c33", blackOnWhite: false}
                webpOptions: {quality: 98}
                layout: FULL_WIDTH
                sizes: "768"
                breakpoints: [320, 600, 768, 1280]
              )
              parent {
                ... on File {
                  relativePath
                }
              }
            }
          }
        }
    `);
    let newSrc = props.src;
    newSrc = props.src.substr(props.src.indexOf('/') + 1);
    const data = allImageSharp.nodes.find(n => n.parent.relativePath === newSrc)?.gatsbyImageData;
    return(
        <Main className={props.className}>
            {
                data
                ? (<GatsbyImage image={data}/>)
                : (<img src={props.src} alt={props.alt}/>)
            }
        </Main>
    )
}

export default ResponsiveImage
