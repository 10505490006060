import * as React from "react"
import styled from "styled-components"
import "@fontsource/merriweather"
import "@fontsource/staatliches"
import "@fontsource/noto-serif-tc/400.css"
import "@fontsource/noto-serif-tc/700.css"
import PortfolioCard from "../../components/portfolio-card";
import {graphql, Link} from 'gatsby'
import SHLHeader from "../../components/SHL-header";
import BlogCard from "../../components/blog-card";

// styles
const pageStyles = {
    fontFamily: "Roboto, sans-serif, serif",
    width: "auto",
    height: "auto",
    minHeight: "100vh",
    padding: "auto",
}
const contentStyles = {
    maxWidth: "768px",
    margin: "auto",
    padding: "16px",
    color: "#d8d8d8",
    backgroundColor: "#181818",
    marginBottom: "16px",
    borderRadius: "8px"
}
const Intro = styled.p`
  font-family: Merriweather, serif;
  font-size: 1.1em;
  a {
    color: #ff008c;
  }
`
const BlogCardContainer = styled.div`
  > *:not(:last-child){
    margin-bottom: 2em;
    border-bottom: 1px solid #c8c8c8;
  }
`
const FullContentWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
`

// markup
const BlogIndexPage = ({data}) => {
    return (
        <main style={pageStyles}>
            <div style={contentStyles}>
                <title>Blog | SH Liu | rarakasm</title>
                <SHLHeader subtitle="Blog"/>
                <FullContentWrapper>
                    {/*<Intro>*/}
                    {/*    Blog blog blog. Blog. <Link to="/about/">feel free to contact me</Link>.*/}
                    {/*</Intro>*/}
                    <BlogCardContainer>
                        {
                            data.allMarkdownRemark.nodes.map((node) =>
                                <BlogCard data={node}/>
                            )
                        }
                    </BlogCardContainer>
                </FullContentWrapper>
            </div>
        </main>
    )
}

export default BlogIndexPage

export const query = graphql`
    query GetAllBlogs {
      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {fileAbsolutePath: {regex: "/(blog)/"}, fields: {encrypted: {ne: true}}}) {
        nodes {
          html
          frontmatter {
            title
            path
            badge
            date(formatString: "YYYY/MM/DD")
            thumbnail
            tags
          }
          fields {
            slug
          }
          excerpt(format: HTML, truncate: false, pruneLength: 200)
        }
      }
    }
`
