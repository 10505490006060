import * as React from "react"
import styled from "styled-components";
import { Link } from 'gatsby';
import Pill from "./pill";
import ResponsiveImage from "./responsive-image";

const NoDecLink = styled(props => <Link {...props} />)`
  :link, :visited, :active{
    text-decoration: none;
  }
`

const Summary = styled.div`
  display: inline-block;
  position: relative;
  font-family: Merriweather, "Noto Serif TC", serif;
  svg {
    display: none;
  }
  max-height: 20em;
  width: 100%;
  overflow: hidden;
  ::after {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(#18181800, #181818);
    width: 100%;
    height: 5em;
    max-height: 50%;
    bottom: 0;
    pointer-events: none;
  }
  a {
    transition: color 0.25s;
    :hover {
      color: white;
    }
  }
`

const Date = styled.div`
  color: #888888;
  font-family: Merriweather, serif;
  margin-bottom: 12px;
  font-size: 1em;
`

const TagContainer = styled.div`
  margin: 6px 0;
`

const Title = styled.h2`
  font-size: 2em;
  font-family: Merriweather, "Noto Serif TC", serif;
  margin: 0;
  color: #ffffff;
  transition: color 0.25s;

  :hover {
    color: #ff008c;
  }
`

const ReadMore = styled.div`
  text-align: right;
  font-size: 1.1em;
  font-family: Merriweather, serif;
  margin: 1em 0;
`

const Main = styled.div`
    margin: 0 0 32px 0;
`

const BlogCard = (props) => {
    return (
        <Main>
            <NoDecLink to={"/blog" + props.data.fields.slug}>
                <Title>
                    {props.data.frontmatter.title}
                </Title>
            </NoDecLink>
            <Date>
                {props.data.frontmatter.date}
            </Date>
            <TagContainer>
                {
                    props.data.frontmatter.tags?.map((tag) =>
                        <Pill tag={tag}/>
                    )
                }
            </TagContainer>
            {
                props.data.frontmatter.thumbnail && (
                    <ResponsiveImage src={props.data.frontmatter.thumbnail}/>
                )
            }
            <Summary dangerouslySetInnerHTML={{__html: props.data.excerpt}}/>
            <NoDecLink to={"/blog" + props.data.fields.slug}>
                <ReadMore> > Read more </ReadMore>
            </NoDecLink>
        </Main>
    )
}

export default BlogCard;
