import * as React from 'react'
import styled, {keyframes} from "styled-components";
import {Link} from "gatsby";


const HeaderWrapper = styled.div`
  z-index: 999;
  width: 100%;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 16px 1em 16px;
  margin-left: -16px;
  background: linear-gradient(180deg, #181818 70%, #18181800 100%);
  @media (max-width: 600px) {
    padding-bottom: 1vw;
  }
`
const HeaderAnimation = keyframes`
  0%{background-position: 0% 0%}
  100%{background-position: -400% 0%}
`
// noinspection CssInvalidPropertyValue
const Header = styled.h1`
  font-family: Staatliches, sans-serif;
  font-size: 3em;
  margin: 0;
  @media (max-width: 600px) {
    font-size: 10vw;
    margin-bottom: 8px;
  }
  color: transparent;
  background: linear-gradient(90deg, #00fff3, #ff008c, #00fff3);
  background-clip: text;
  -webkit-background-clip: text;

  background-size: 200% 100%;
  animation: ${HeaderAnimation} 16s linear infinite;

  span {
    color: white;
    background: transparent;
  }

  a {
    color: transparent;
    text-underline: none;
  }
`

const SHLHeader = (props) => {
    return (
        <HeaderWrapper>
            <Header><Link to="/">SHL</Link> <Link to={props.subtitleLink}><span>{props.subtitle}</span></Link></Header>
        </HeaderWrapper>
    )
}

export default SHLHeader
